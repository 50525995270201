<template>
  <div style="border-radius: 8px" class="row">
    <div class="border">
      <div class="card p-2 pb-0 filter">
        <div class="row w-100 pe-lg-0 me-1 py-2">
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.full_name" class="form-check-input" id="full_name"
                       type="checkbox" tabindex="3"/>
                <label class="form-check-label" for="full_name"> Name</label>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.serial_no" class="form-check-input" id="serial_no"
                       type="checkbox" tabindex="3"/>
                <label class="form-check-label" for="serial_no"> ID</label>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.dob_gender" class="form-check-input" id="dob_gender"
                       type="checkbox"
                       tabindex="3"/>
                <label class="form-check-label" for="dob_gender">Sex, Age</label>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <div class="form-check">
                <input v-model="checkMark.contact_no" class="form-check-input" id="po_no" type="checkbox"
                       tabindex="3"/>
                <label class="form-check-label" for="po_no">Contact No</label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <label for="quantity">Quantity</label>
              <input v-model="sticker.quantity" class="form-control" id="quantity" type="number"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-1">
              <label for="custom_text">Text</label>
              <textarea v-model="sticker.custom_text" class="form-control" id="custom_text"></textarea>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="mb-1">
              <button onclick="window.print()" class="btn btn-primary mt-1">Print</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-2">
        <div class=" label-border-outer ">
          <div class="label-border-internal">
            <div v-for="index in barcodeQty" :key="index" class="sticker-border text-center loop-dev">
              <div style="display:inline-block;vertical-align:middle;line-height:15px !important;">
                <span v-if="checkMark.full_name" class="text-label">{{ sticker.full_name }}</span>
                <span v-if="checkMark.serial_no" class="text-label">{{ sticker.serial_no }}</span>
                <span v-if="checkMark.dob_gender" class="text-label">
                  {{ sticker.birthday ? (calculateAge(sticker.birthday) + ', ' + capitalize(sticker.gender)) : '' }}
                </span>
                <span v-if="checkMark.contact_no" class="text-label">
                  {{ getPatientDetails(sticker, 'mobile_no') }}
                </span>
                <span class="text-label text-pre-wrap">{{ sticker.custom_text }}</span>
                <div v-html="barcode"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleCompanyContact from "@/services/modules/companyContact";
import handleBarcode from "@/services/modules/barcode";
import handleHospital from "@/services/modules/hospital";

const {fetchContactDetails} = handleCompanyContact();
const {calculateAge} = handleHospital();
const {fetchBarcode} = handleBarcode();
const route = useRoute();

let companyId = computed(() => route.params.companyId);
const barcodeQty = computed(() => parseInt(sticker.value.quantity));

let barcode = ref(null);
let sticker = ref({
  quantity: 1,
  birthday: '',
  gender: '',
  custom_text: '',
  full_name: '',
  serial_no: '',
});

let checkMark = ref({
  dob_gender: false,
  contact_no: false,
  pi_no: false,
  full_name: true,
  serial_no: true,
})

const getContactDetails = async (patientId, type = 'activities') => {
  try {
    const res = await fetchContactDetails(type, patientId, '?company_id=' + companyId.value);
    if (!res.status) {
      return sticker.value = {}
    }
    sticker.value = res.data.contact_profile;
    sticker.value.quantity = 1;
    getBarCode(sticker.value.serial_no)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const getBarCode = (serial_no) => {
  fetchBarcode(`?code=${serial_no}&qrcode=${serial_no}&width=2&height=35`).then((res) => {
    barcode.value = res.barcode;
  })
}

const getPatientDetails = (patient, fieldName) => {
  if (patient.profile_items) {
    const field = patient.profile_items.find(info => info.field === fieldName)
    return (field && field.value) ?? '';
  }
  return "";
}

const capitalize = (text) => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
}

onMounted(async () => {
  await getContactDetails(route.params.patientId)
})

</script>

<style>
.text-pre-wrap {
  white-space: pre-wrap;
}

.text-center {
  text-align: center;
}

.loop-dev {
  height: auto;
  padding-bottom: 5px;
  margin-bottom: 10px;
  line-height: 1.0000in;
  width: 1.94in !important;
  display: inline-block;
}

.text-label {
  font-size: 14px;
  display: block;
  margin: 3px
}

/*Css related to printing of barcode*/
.label-border-outer {
  line-height: 16px;
  page-break-after: always;
  border: 0.1px solid grey !important;
}

.label-border-internal {
  margin: 24px;
}

.sticker-border {
  border: 0.1px dotted grey !important;
  overflow: hidden;
  box-sizing: border-box;
}

@media print {
  .content-wrapper {
    border-left: none !important; /*fix border issue on invoice*/
  }

  .label-border-outer {
    border: none !important;
  }

  .label-border-internal {
    border: none !important;
  }

  .sticker-border {
    border: none !important;
  }

  #toast-container {
    display: none !important;
  }

  .tooltip {
    display: none !important;
  }

  .btn, .main-menu, .header-navbar, .filter {
    display: none !important;
  }

  .app-content, .card {
    padding: 0px !important;
  }

  .label-border-internal {
    margin: 0px !important;
  }

  @page {
    margin: 0;
  }
}

@page {
  size: 8.5000in 11.0000in ;
  margin-top: 0in;
  margin-bottom: 0in;
  margin-left: 0in;
  margin-right: 0in;

}
</style>
